import * as R from 'ramda';
import styled from 'styled-components';
import { string, shape } from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { pathOrNothingUI, getDateByPropWithTime } from '@poly/client-utils';
import { insertParamsIntoURL } from '@poly/utils';
import { getThemeColor } from '@poly/admin-book';
import {
  useRouterQuery,
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
} from '@poly/client-routing';

import { routes } from '../../routes.js';
import { Icon } from '../../icons/Icon.js';
import { Tabs } from '../../components/Tabs.js';
import { Loader } from '../../components/Loader.js';
import { ScreenHeader } from '../../components/ScreenHeader.js';
import { ProjectStatus } from '../../components/ProjectStatus.js';
import { BlockWithLabel } from '../../components/BlockWithLabel.js';
import { ProjectPriority } from '../../components/ProjectPriority.js';
import { ProjectDetailsAssetsTab } from './ProjectDetailsAssetsTab.js';
import { ProjectDetailsUpdatesTab } from './ProjectDetailsUpdatesTab.js';
import { useProjectDetails } from './useProjectDetails.js';
import { NotFoundScreen } from '../NotFoundScreen.js';

export const ScreenHeaderS = styled(ScreenHeader)`
  border-bottom: 2px solid ${getThemeColor(['midLight'])};
`;

const ProjectContentWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 350px;
  overflow-y: scroll;
`;

const ProjectDetailsWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 24px 24px 0 24px;
  width: calc(100% - 48px);

  > div {
    width: 50%;
  }

  > div:nth-child(1),
  > div:nth-child(2) {
    width: 100%;
  }
`;

const ProjectDetailsFirstRowS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ProjectStatusAndPriorityWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  > div:first-child {
    margin-right: 8px;
  }
`;

const ProjectDetailsTabsS = styled(Tabs)`
  height: calc(100% - ${R.prop('contentHeight')}px);
`;

function AddUpdateButton({ projectId }) {
  const navigate = useNavigate();
  const location = useLocation();

  const addProjectUpdateUrl = insertParamsIntoURL(
    { projectId },
    routes.addProjectUpdate,
  );

  const onClick = () =>
    navigate(addProjectUpdateUrl, { state: { previous: location } });

  return <Icon name="speech" onClick={onClick} />;
}

AddUpdateButton.propTypes = { projectId: string };

function ProjectStatusAndPriorityBlock({ project }) {
  return (
    <ProjectStatusAndPriorityWrapperS>
      <ProjectPriority {...project} />
      <ProjectStatus {...project} />
    </ProjectStatusAndPriorityWrapperS>
  );
}

ProjectStatusAndPriorityBlock.propTypes = {
  project: shape({
    priority: string,
    status: string.isRequired,
  }),
};

export function ProjectDetailsScreen() {
  const location = useLocation();
  const { projectId } = useParams();
  const projectDetailsRef = useRef(null);
  const { tab } = useRouterQuery(['tab']);
  const [contentHeight, setContentHeight] = useState(0);
  const { project, loading } = useProjectDetails(projectId);

  const routesMatch = matchRoutes(
    [{ path: routes.addProjectUpdate }],
    location.state?.previous || {},
  );

  useEffect(() => {
    if (projectDetailsRef?.current) {
      setContentHeight(projectDetailsRef.current.clientHeight);
    }
  }, [projectDetailsRef?.current]);

  const calculatedContentHeight =
    (contentHeight < 350 ? contentHeight : 350) + 73;

  if (!loading && R.isEmpty(project)) {
    return <NotFoundScreen />;
  }

  if (loading) {
    return null;
  }

  const tabs = [
    {
      key: 'assets',
      title: 'Assets',
      content: <ProjectDetailsAssetsTab projectId={project.projectId} />,
    },
    {
      key: 'updates',
      title: 'Updates',
      content: <ProjectDetailsUpdatesTab projectId={project.projectId} />,
    },
  ];

  return (
    <>
      <ScreenHeaderS
        goBack
        title="Project Details"
        {...(routesMatch ? { route: routes.projects } : {})}
        toolBar={project && <AddUpdateButton projectId={project?._id} />}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <ProjectContentWrapperS>
            <ProjectDetailsWrapperS ref={projectDetailsRef}>
              <ProjectDetailsFirstRowS>
                <BlockWithLabel label="Project ID" value={project.projectId} />
                <ProjectStatusAndPriorityBlock project={project} />
              </ProjectDetailsFirstRowS>
              <BlockWithLabel
                label="Description"
                value={pathOrNothingUI(['description'], project)}
              />
              <BlockWithLabel
                label="Start Date"
                value={getDateByPropWithTime('startDate')(project)}
              />
              <BlockWithLabel
                label="End Date"
                value={getDateByPropWithTime('endDate')(project)}
              />
              <BlockWithLabel
                label="Requestor"
                value={pathOrNothingUI(['contact', 'fullName'], project)}
              />
              <BlockWithLabel
                label="Client Service Manager"
                value={pathOrNothingUI(['manager', 'fullName'], project)}
              />
            </ProjectDetailsWrapperS>
          </ProjectContentWrapperS>
          <ProjectDetailsTabsS
            tabs={tabs}
            activeTab={tab || tabs[0].key}
            contentHeight={calculatedContentHeight}
          />
        </>
      )}
    </>
  );
}
